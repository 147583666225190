module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"backgroundColor":"none","maxWidth":900,"linkImagesToOriginal":false,"disableBgImage":true,"wrapperStyle":"margin: 1.5rem 0;"}}],"remarkPlugins":[null,null,null,[null,{"style":"smart"}],null]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-code-notes/gatsby-browser.js'),
      options: {"plugins":[],"basePath":"/","contentPath":"code-notes","gitRepoContentPath":"https://github.com/itsmohsenjalali/mohsencodenote/tree/master/code-notes/","showDescriptionInSidebar":true,"showThemeInfo":false,"logo":"https://raw.githubusercontent.com/mrmartineau/gatsby-theme-code-notes/master/assets/logo.png","openSearch":{"siteUrl":"codenote.mohsenjalali.ir","siteShortName":"Gatsby Theme Code Notes Example","siteTags":"front-end","siteDescription":"A Gatsby theme for storing your code-related notes"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mohsen Jalali","short_name":"CodeNote","start_url":"/","scope":".","display":"standalone","background_color":"#663399","theme_color":"#333333","icon":"logo-site.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"0c29e6ba8368706d04df0e94f1deb6cc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/*","/tag/*"]},
    }]
